<script setup>
import { computed } from 'vue'
import { useAgentStore } from '~/store/agent'

const agentStore = useAgentStore()
const estateAgentNames = computed(() => agentStore.estateAgentNames)
</script>

<template>
  <el-select v-model="agentStore.currentEstateAgentName" size="large" placeholder="Select" filterable>
    <el-option value="all" label="All Estate Agents" />
    <el-option v-for="estateAgentName in estateAgentNames" :key="estateAgentName" :label="estateAgentName" :value="estateAgentName" />
  </el-select>
</template>
