<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronUpIcon } from '@heroicons/vue/20/solid'
import { useNavigationStore } from '~/store/navigation.js'
import { navigateTo } from 'nuxt/app'
import { useAuthStore } from '~/store/auth.js'
import SentryFeedbackButtonHorizontal from './SentryFeedbackButtonHorizontal.vue'

const props = defineProps({
  navigation: {
    type: Array,
    required: true,
  },
})

const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()

const navigationStore = useNavigationStore()

const isExpanded = computed(() => navigationStore.isExpanded)

const handleClick = (href) => {
  navigateTo(href)
}
</script>

<template>
  <nav class="flex flex-col gap-3">
    <ul role="list" class="flex flex-1 flex-col gap-y-7">
      <li>
        <ul role="list" class="-mx-2 space-y-1">
          <li v-for="(item, itemIndex) in navigation.filter((item) => item.visible)" :key="itemIndex" class="relative">
            <Disclosure
              v-if="item.children"
              v-slot="{ open, close }"
              as="div"
              :default-open="item.isActive"
              :data-id="`collapse${itemIndex}`"
            >
              <DisclosureButton
                class="relative z-[1] flex w-full cursor-pointer items-center gap-3 rounded border border-b-0 px-2 py-3 text-left text-sm transition-all duration-300 ease-in-out focus:outline-none focus-visible:ring"
                :class="{
                  'bg-primary text-white focus-visible:ring-primary/75': !item.alternative && item.isActive && !open,
                  'hover:bg-primary hover:text-white focus-visible:ring-primary/75': !item.isActive && !item.alternative,
                  'border-transparent': !open && !item.alternative,
                  'bg-white': open && (!item.alternative ?? true),
                  'border-transparent bg-yellow-400 text-black focus-visible:ring-yellow-400/75': item.alternative ?? false,
                }"
              >
                <component :is="item.icon" :class="isExpanded ? '' : 'mx-auto'" class="h-5 w-5 flex-shrink-0" />
                <div v-if="isExpanded" class="line-clamp-2 flex flex-1 flex-col gap-px">
                  <p class="text-sm" v-html="item.title" />
                  <p v-if="item.subtitle" class="text-xs">{{ item.subtitle }}</p>
                </div>
                <ChevronUpIcon v-if="isExpanded" :class="open ? 'rotate-180 transform' : ''" class="h-5 w-5" />
              </DisclosureButton>
              <button v-show="false" :data-id="`collapseClose${itemIndex}`" @click="close" />
              <DisclosurePanel>
                <ul role="list" class="relative z-[0] -mt-[3px] space-y-1 rounded border pt-0.5">
                  <li
                    v-for="(child, childIndex) in item.children.filter((item) => item.visible)"
                    :key="`child${childIndex}`"
                    class="first:mt-1"
                  >
                    <div
                      class="flex w-full cursor-pointer items-center gap-3 rounded px-2 py-3 text-left text-sm transition-all duration-300 focus:outline-none focus-visible:ring"
                      :class="{
                        'bg-primary text-white focus-visible:ring-primary/75': child.isActive,
                        'hover:bg-primary hover:text-white focus-visible:ring-primary/75': !child.isActive,
                      }"
                      @click="handleClick(child.href)"
                    >
                      <component :is="child.icon" :class="isExpanded ? '' : 'mx-auto'" class="h-5 w-5 flex-shrink-0" />
                      <div v-if="isExpanded" class="line-clamp-2 flex flex-1 flex-col gap-px">
                        <p class="text-sm" v-html="child.title" />
                        <p v-if="child.subtitle" class="text-xs">{{ child.subtitle }}</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </DisclosurePanel>
            </Disclosure>
            <div
              v-else
              class="flex w-full cursor-pointer items-center gap-3 rounded px-2 py-3 text-left text-sm transition-all duration-300 focus:outline-none focus-visible:ring"
              :class="{
                'bg-primary text-white focus-visible:ring-primary/75': item.isActive,
                'hover:bg-primary hover:text-white focus-visible:ring-primary/75': !item.isActive,
              }"
              @click="handleClick(item.href)"
            >
              <component :is="item.icon" :class="isExpanded ? '' : 'mx-auto'" class="h-5 w-5 flex-shrink-0" />
              <div v-if="isExpanded" class="line-clamp-2 flex flex-1 flex-col gap-px">
                <p class="text-sm" v-html="item.title" />
                <p v-if="item.subtitle" class="text-xs">{{ item.subtitle }}</p>
              </div>
            </div>
          </li>
        </ul>
      </li>
      <li>
        <div class="sentry-container">
          <SentryFeedbackButtonHorizontal class="max-w-full whitespace-normal leading-5" :user="authStore.user" />
        </div>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss">
/*hide this at the smallest possible point that the top navigation bar looks good. */
@media only screen and (min-width: 1475px) {
  .sentry-container {
    display: none;
  }
}
</style>
