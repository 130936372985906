<script setup>
import { Menu } from '@element-plus/icons-vue'
import { navigateTo } from 'nuxt/app'
import { useNavigationStore } from '~/store/navigation.js'
import { useAuthStore } from '~/store/auth.js'
import SentryFeedbackButtonHorizontal from './SentryFeedbackButtonHorizontal.vue'

const emits = defineEmits(['logout'])

const authStore = useAuthStore()
const store = useNavigationStore()

function handleSettings() {
  navigateTo('/account')
}
const role = computed(() => (authStore.user?.super_user ? 'Admin' : authStore.user?.job_role))
const internalUser = computed(() => (authStore.isInternalUser ? 'Internal User' : ''))

function handleLogout() {
  emits('logout')
}
</script>

<template>
  <header class="bg-white px-6 py-3 shadow">
    <div class="flex items-center gap-6">
      <el-icon size="24" class="block text-primary lg:hidden" @click="store.openMobile()">
        <Menu />
      </el-icon>
      <agent-selector v-if="authStore.isInternalUser" class="w-full max-w-sm" />
      <branch-selector class="w-full max-w-sm" />
      <div class="sentry-container xl2:visible"><SentryFeedbackButtonHorizontal :user="authStore.user" /></div>
      <div class="flex flex-1 items-center justify-end gap-6">
        <el-dropdown type="success" placement="bottom-end" popper-class="w-64" size="large">
          <template #default>
            <div class="flex items-center gap-3">
              <div class="hidden text-right md:block">
                <pre class="text-sm font-semibold">{{ authStore.user?.full_name }}</pre>
                <pre class="text-xs">{{ role }} {{ internalUser }}</pre>
              </div>
              <el-avatar :icon="ElIconUserFilled" />
            </div>
          </template>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :icon="ElIconTools" @click="handleSettings">Settings</el-dropdown-item>
              <el-dropdown-item :icon="ElIconUserFilled" @click="handleLogout">Logout</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </header>
</template>

<style scoped>
.agent-button {
  @apply border border-gray-500;
}
.sentry-container {
  @apply hidden;
}
/*Smallest possible point where all 3 buttons fit nicely. */
@media only screen and (min-width: 1475px) {
  .sentry-container {
    @apply inline;
  }
}
</style>
