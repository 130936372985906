import { defineStore } from 'pinia'

export const useNavigationStore = defineStore({
  id: 'navigation',
  persist: true,
  state: () => ({
    mobileOpen: false,
    expanded: true,
    hoverExpanded: false,
  }),
  getters: {
    isExpanded: (state) => state.expanded || state.hoverExpanded,
  },
  actions: {
    toggleExpanded() {
      this.expanded = !this.expanded
    },
    toggleHoverExpanded(isExpanded) {
      this.hoverExpanded = isExpanded
    },
    openMobile() {
      this.mobileOpen = true
    },
    closeMobile() {
      this.mobileOpen = false
    },
  },
})
